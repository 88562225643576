import React from "react"
//import {Link} from "gatsby"

import {renderRichText} from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import {graphql} from "gatsby"
import { Container } from "@chakra-ui/layout"

const VacaniesPage = ({data}) => {

  return (
    <Layout>

      <SEO title="Web development and outsourcing"/>

      {/*
      <section className="section teaser">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h1 className="title is-size-2-touch">
                Web development and outsourcing agency in Bishkek, Kyrgyzstan
              </h1>
            </div>
            <div className="column">
              <img src={data.file.publicURL} className="is-pulled-right" alt="teaser graphic"/>
            </div>
          </div>
        </div>
      </section>
      */}

      <section className="section services">
        <Container>
          <div className="columns">
            {data.services.nodes.map((service, index) => (
              <div className="column service">
                <GatsbyImage className="icon" fixed={service.image.fixed}/>
                <h2 className="subtitle is-uppercase">
                  {service.title}
                </h2>
                <div className="description">
                  {renderRichText(service.serviceDescription)}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <section className="section technologies">
        <Container>
          <h3 className="subtitle is-uppercase">
            Technologies we love
          </h3>
          <div className="columns is-multiline technologies">
            {data.technologies.nodes.map((technology, index) => (
              <div className="column is-half technology">
                <div className="level is-mobile">
                  <div className="level-left">

                    <div className="level-item">
                      <img className="image" src={technology.image.file.url} alt={technology.name}/>
                    </div>

                    <div className="level-item">
                      <p className="name is-size-3-touch">
                        {technology.name}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <section className="hero work">
        <div className="hero-body">
          <Container>

            <p className="phone">
              <a href="tel:+4915234030328">+4915234030328 (WhatsApp)</a>
            </p>


            <p className="email">
              <a href="mailto:hey@maxsoftware.dev">hey@maxsoftware.dev</a>
            </p>

          </Container>
        </div>
      </section>

      <div className="container" >
        <hr />
      </div>

    </Layout>
  )

};

export const query = graphql`
  query {
    file(relativePath: { eq: "start_illustration.svg" }) {
        publicURL
    }
    services: allContentfulService(filter: {node_locale: {eq: "en-US"}}, sort: {order: ASC, fields: title}) {
      nodes {
        title
        serviceDescription {
          raw
        }
        image {
          gatsbyImageData(width: 100, height: 100)
        }
      }
    }
    technologies: allContentfulTechnology(filter: {node_locale: {eq: "en-US"}}, sort: {order: ASC, fields: name}) {
      nodes {
        name
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export default VacaniesPage
